import styles from './top.module.css';
import { Link } from 'react-router-dom';
import { useModal } from '../../hooks/useModal';
import { NavLinks } from '../navlinks/NavLinks';
import { OffCanvas } from '../offcanvas/Offcanvas';

export function Top() {
    const { isVisible, setIsVisible, toggleModal } = useModal();
    return (
        <div className={styles.top}>
            <div className={styles.container}>
                <button
                    className={isVisible ? styles.close : styles.hamburger}
                    onClick={toggleModal}
                />
                <OffCanvas
                    isVisible={isVisible}
                    setIsVisible={setIsVisible}
                >
                    <nav className={styles.navigationMobile}>
                        <ul className={styles.containerMobile}>
                            <NavLinks />
                        </ul>
                    </nav>
                </OffCanvas>
                <Link
                    to='/'
                    className={styles.logo}
                >
                    Меламин Трейд
                </Link>
                <ul className={styles.info}>
                    <li className={styles.time}>c 9:00 до 18:00</li>
                    <li className={styles.phone}>+7 (967) 050-40-90</li>
                    <li className={styles.phone}>+7 (909) 997-79-69</li>
                    <li className={styles.phoneButtonContainer}>
                        {/* eslint-disable-next-line  jsx-a11y/anchor-has-content */}
                        <a
                            href='tel: +79670504090'
                            className={styles.phoneButton}
                        /></li>
                </ul>
            </div>
        </div>
    )
}
