import { useState, useMemo } from 'react';
import styles from './payment.module.css';
import { Breadcrumbs } from '../../components/breadcrumbs/Breadcrumbs';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { YMaps, Map, Placemark } from 'react-yandex-maps';
import placeMarkImage from '../../images/component-maps-placemark.svg';
import useVH from 'react-vh';
import { Seo } from '../../helpers/Seo';

const companyGeo = [55.459986, 38.441462];
const manufactureGeo = [55.911512, 38.024303];

export function Payment() {
    useVH();
    const [tabIndex, setTabIndex] = useState(0);
    let isRequisitesTabActive = tabIndex === 0;

    const mapState = useMemo(() => ({ center: isRequisitesTabActive ? companyGeo : manufactureGeo, zoom: 17 }), [
        isRequisitesTabActive
    ])

    return (
        <>
            <article className={styles.payment}>
                <Breadcrumbs />
                <h1 className={styles.title}>Доставка и оплата</h1>
                <div className={styles.paymentContainer}>
                    <div className={styles.info}>
                        <Tabs
                            selectedIndex={tabIndex}
                            onSelect={index => setTabIndex(index)}
                            selectedTabClassName={styles.active}
                            selectedTabPanelClassName={styles.tabsContainer}
                        >
                            <TabList className={styles.tabs}>
                                <Tab className={styles.name}>Реквизиты для оплаты</Tab>
                                <Tab className={styles.name}>Доставка</Tab>
                            </TabList>
                            <TabPanel>
                                <ul className={styles.requisites}>
                                    <li><span className={styles.regular}>Наименование компании:</span> ООО «Меламин
                                        Трейд»
                                        (ООО «МЛТ»)
                                    </li>
                                    <li><span className={styles.regular}>Юридический адрес:</span> 140250, Московская
                                        область. городской округ Воскресенск,
                                        г. Белоозёрский, ул. Молодежная, д. 24, кв. 66
                                    </li>
                                    <li><span className={styles.regular}>ОГРН:</span> 1215000107981</li>
                                    <li><span className={styles.regular}>ИНН/КПП:</span> 5005071011/500501001</li>
                                    <li><span className={styles.regular}>Банк:</span> АО «Альфа Банк» г.Москва</li>
                                    <li><span className={styles.regular}>Расчетный счет:</span> 40702810802410003829
                                    </li>
                                    <li><span className={styles.regular}>БИК:</span> 044525593</li>
                                    <li>
                                        <span className={styles.regular}>Корреспондентский счет:</span> 30101810200000000593
                                    </li>
                                    <li><span className={styles.regular}>Тел.:</span> +7 (967) 050-40-90, +7 (909) 997-79-69</li>
                                    <li><span className={styles.regular}>E-mail:</span> order@melaminetrade.ru</li>
                                </ul>
                            </TabPanel>
                            <TabPanel className={styles.text}>
                                <p>
                                    Доставка может осуществляться, как за счёт компании ООО «Меламин Трейд», так и на
                                    условиях самовывоза со склада компании, расположенного по адресу:
                                    г. Щелково, ул. Заводская, д.2-65, строение 1, помещение 2, офис 1.
                                </p>
                                <p>Основное время работы склада: c 9-00 до 18-00 в рабочие дни. Вывоз в другое время
                                    можно
                                    согласовывать отдельно.</p>
                            </TabPanel>
                        </Tabs>
                    </div>
                    <YMaps>
                        <Map
                            state={mapState}
                            className={styles.map}
                        >
                            <Placemark
                                geometry={isRequisitesTabActive ? companyGeo : manufactureGeo}
                                options={{
                                    iconLayout: 'default#image',
                                    iconImageHref: placeMarkImage,
                                    iconImageSize: [30, 40]
                                }}
                            />
                        </Map>
                    </YMaps>
                </div>
            </article>
            <Seo
                title='Доставка и оплата'
                description='Доставка может осуществляться, как за счёт компании ООО «Меламин Трейд», так и на условиях самовывоза со склада компании'
                keywords='меламинтрейд доставка'
                language='ru'
                siteName='ООО «Меламин Трейд»'
            />
        </>
    )
}
